var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('CRUD',{ref:"crud",attrs:{"idMethod":"params","fields":_vm.fields,"table":_vm.table,"api":_vm.api},scopedSlots:_vm._u([{key:"form.input.UPSUsername",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{attrs:{"hide-details":"auto","dense":"","error-messages":_vm.error,"label":item.label,"value":item.value,"disabled":item.disabled},on:{"change":function($event){return _vm.onUserNameChange($event, item)}}})]}},_vm._l((_vm.tableFields),function(table){return {key:("form.input." + (table.name)),fn:function(ref){return [_c('CRUD',{key:table.field,ref:("crud_" + (table.name)),refInFor:true,attrs:{"form-size":"500","fields":table.input.data.fields,"table":table.input.data.table,"api":{
          url: '/ccms/table/',
          params: {
            tableName: table.name,
            agentId: _vm.$route.params.id
          }
        }},on:{"form:input":_vm.onEmpRecFormInput,"form:load":_vm.initEmpRecForm},scopedSlots:_vm._u([(table.name == 'cnetd.employment')?{key:"form.input.SourceID",fn:function(ref){
        var item = ref.item;
return [_c('v-combobox',{ref:"recSource",refInFor:true,attrs:{"search-input":_vm.recSource.search,"items":_vm.recSourceList,"hide-details":"auto","hide-selected":"","dense":"","label":item.label},on:{"update:searchInput":function($event){return _vm.$set(_vm.recSource, "search", $event)},"update:search-input":function($event){return _vm.$set(_vm.recSource, "search", $event)},"change":function($event){return _vm.onSourceChange($event, item)}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" No results matching \""),_c('strong',[_vm._v(_vm._s(_vm.recSource.search))]),_vm._v("\". Press "),_c('kbd',[_vm._v("enter")]),_vm._v(" to create a new one ")])],1)],1)]},proxy:true}],null,true),model:{value:(_vm.recSource.selected),callback:function ($$v) {_vm.$set(_vm.recSource, "selected", $$v)},expression:"recSource.selected"}})]}}:null],null,true)})]}}})],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }