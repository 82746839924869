<template>
  <v-container fluid>
    <CRUD ref="crud" idMethod="params" :fields="fields" :table="table" :api="api">
      <template v-slot:[`form.input.UPSUsername`]="{ item }">
        <v-text-field
          hide-details="auto"
          dense
          :error-messages="error"
          :label="item.label"
          :value="item.value"
          :disabled="item.disabled"
          @change="onUserNameChange($event, item)"
        ></v-text-field>
      </template>
      <template v-for="table in tableFields" v-slot:[`form.input.${table.name}`]="{}">
        <CRUD
          :ref="`crud_${table.name}`"
          @form:input="onEmpRecFormInput"
          @form:load="initEmpRecForm"
          :key="table.field"
          form-size="500"
          :fields="table.input.data.fields"
          :table="table.input.data.table"
          :api="{
            url: '/ccms/table/',
            params: {
              tableName: table.name,
              agentId: $route.params.id
            }
          }"
        >
          <template
            v-if="table.name == 'cnetd.employment'"
            v-slot:[`form.input.SourceID`]="{ item }"
          >
            <v-combobox
              :search-input.sync="recSource.search"
              :items="recSourceList"
              hide-details="auto"
              hide-selected
              dense
              :label="item.label"
              v-model="recSource.selected"
              @change="onSourceChange($event, item)"
              ref="recSource"
            >
              <template v-slot:no-data>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>
                      No results matching "<strong>{{ recSource.search }}</strong
                      >". Press <kbd>enter</kbd> to create a new one
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-combobox>
          </template>
        </CRUD>
      </template>
    </CRUD>
  </v-container>
</template>

<script>
import axios from "axios";
import CRUD from "@/components/util/CRUD/CRUD";

export default {
  components: {
    CRUD
  },
  computed: {
    tableFields() {
      return this.fields.filter((field) => field.input?.control == "table");
    },
    recSourceList() {
      return this.recSource.items.filter((r) => r.RecID == this.recSource.recType);
    }
  },
  data() {
    return {
      source: undefined,
      recSource: {
        selected: null,
        search: null,
        items: [],
        recType: "18"
      },
      error: "",
      api: {
        url: "/ccms/table/",
        params: {
          tableName: "cnetd.tempstaff"
        }
      },
      table: {
        text: "Agents", // table name to display in crud component
        key: "id", // primary key of table for various usage
        default: {
          //* used for default table option
          page: 1,
          itemsPerPage: 10,
          sortBy: ["DateJoined"],
          sortDesc: [true]
        },
        components: {
          //* for hiding component that are not needed
          filter: true, //d[true]
          delete: true, //d[true]
          add: true
        },
        partialUpdate: true //default true
      },
      fields: [
        {
          text: "NRIC",
          name: "id",
          searchable: true,
          editable: false
        },
        {
          text: "Name",
          name: "TName",
          searchable: true,
          input: {
            validation: ["mandatory"]
          }
        },
        {
          text: "UPS Username",
          name: "UPSUsername",
          searchable: true,
          input: {
            if: { update: "disabled" }
          }
        },
        {
          text: "Password",
          name: "Password",
          listable: false,
          input: {
            control: "password",
            validation: ["mandatory"]
          }
        },
        {
          text: "Contact",
          name: "Contact",
          searchable: true,
          input: {}
        },
        {
          text: "Languages",
          name: "Languages",
          input: {
            type: "L",
            multiple: true
          },
          searchable: true,
          foreign: {
            table: "cnetd.Language",
            key: "Language",
            display: "Language",
            where: `AreaID = '${this.$store.state.auth.user.areaid}'`
          }
        },
        {
          text: "Reason for Leaving",
          name: "ReasonOfLeavingId",
          input: {
            type: "L"
          },
          foreign: {
            table: "cnetd.ReasonOfLeaving",
            key: "ReasonOfLeavingId",
            display: "Leaving_Reason"
          }
        },
        {
          text: "NDA Signed",
          name: "NDA",
          input: {
            type: "L",
            control: "checkbox",
            lov: [{ text: "Yes", value: "1" }]
          }
        },
        {
          text: "Source",
          name: "Source",
          editable: false
        },
        {
          text: "Date Joined",
          name: "DateJoined",
          searchable: true,
          editable: false
        },
        {
          text: "Date Left",
          name: "DateLeft",
          searchable: true,
          editable: false
        },
        {
          text: "Employment Record",
          name: "cnetd.employment",
          listable: false,
          input: {
            if: { add: "hidden" },
            cols: "12",
            control: "table",
            data: {
              table: {
                text: "Employment Record",
                key: "employmentId",
                default: {
                  itemsPerPage: -1,
                  attrs: { "hide-default-footer": true }
                },
                components: {
                  filter: false
                },
                partialUpdate: false
              },
              fields: [
                {
                  text: "Job",
                  name: "JobTitleID",
                  input: {
                    type: "L",
                    cols: 12,
                    validation: ["mandatory"]
                  },
                  foreign: {
                    table: "cnetd.jobtitlemst",
                    key: "JobTitleID",
                    display: "JobTitleNM"
                  }
                },
                {
                  text: "Recruitment Type",
                  name: "RecID",
                  input: {
                    type: "L",
                    cols: 12,
                    control: "radio",
                    validation: ["mandatory"],
                    value: "10"
                  },
                  foreign: {
                    table: "cnetd.rectypemst",
                    key: "RecID",
                    display: "RecType"
                  }
                },
                {
                  text: "Recruitment Source",
                  name: "SourceID",
                  input: {
                    type: "L",
                    cols: 12
                  },
                  foreign: {
                    table: "cnetd.recsourcemst",
                    key: "SourceID",
                    display: "SourceName",
                    where: `AreaID = '${this.$store.state.auth.user.areaid}'`
                  }
                },
                {
                  text: "Date Join",
                  name: "StartDate",
                  input: {
                    type: "D",
                    cols: 12,
                    validation: ["mandatory"]
                  }
                },
                {
                  text: "Date Left",
                  name: "EndDate",
                  input: {
                    type: "D",
                    cols: 12
                  }
                }
              ]
            }
          }
        },
        {
          text: "Hourly Rate Record",
          name: "cnetd.hourly_rate",
          listable: false,
          input: {
            if: { add: "hidden" },
            cols: "6",
            control: "table",
            data: {
              table: {
                text: "Hourly Rate",
                key: "hourly_rateId",
                default: {
                  itemsPerPage: -1,
                  attrs: { "hide-default-footer": true }
                },
                components: {
                  filter: false
                }
              },
              fields: [
                {
                  text: "Hourly Rate",
                  name: "Hourly_Rate",
                  input: {
                    cols: 12,
                    validation: ["mandatory"]
                  }
                },
                {
                  text: "Effective Date",
                  name: "effective_date",
                  input: {
                    type: "D",
                    cols: 12,
                    validation: ["mandatory"]
                  }
                }
              ]
            }
          }
        },
        {
          text: "Holiday Calculator",
          name: "cnetd.holiday_rate",
          listable: false,
          input: {
            if: { add: "hidden" },
            cols: "6",
            control: "table",
            data: {
              table: {
                text: "Holiday Rate",
                key: "holiday_rateId",
                default: {
                  itemsPerPage: -1,
                  attrs: { "hide-default-footer": true }
                },
                components: {
                  filter: false
                }
              },
              fields: [
                {
                  text: "Hourly Rate",
                  name: "HourlyRate",
                  input: {
                    cols: 12,
                    validation: ["mandatory"]
                  }
                },
                {
                  text: "Effective Date",
                  name: "EffectiveDate",
                  input: {
                    type: "D",
                    cols: 12,
                    validation: ["mandatory"]
                  }
                }
              ]
            }
          }
        }
      ]
    };
  },
  methods: {
    onEmpRecFormInput(e) {
      if (e.item.field == "RecID") {
        this.recSource.recType = e.values.RecID;
        this.recSource.selected = null;
        this.$refs[`crud_cnetd.employment`][0].updateValue("SourceID", null);
      }
    },
    initEmpRecForm(e) {
      this.fetchRecSrcList().then(() => {
        this.recSource.recType = e.RecID;
        if (e.SourceID) {
          let srcId = "" + e.SourceID;
          let srcName = this.recSource.items.find((e) => e.value == srcId)?.text ?? srcId;
          this.recSource.selected = { text: srcName, value: srcId };
        }
      });
    },
    fetchRecSrcList() {
      return this.$axios.get("/ccms/getRecSource").then((res) => {
        if (res.data) {
          this.recSource.items = res.data;
        }
      });
    },
    onSourceChange(e, item) {
      this.$refs[`crud_cnetd.employment`][0].updateValue(item.field, e);
      this.$nextTick(() => {
        this.$refs.recSource[0].isMenuActive = false;
      });
    },
    onUserNameChange(e, item) {
      this.$refs.crud.updateValue(item.field, e);
      if (e) {
        this.source && this.source.cancel("Operation canceled due to new request.");
        this.source = axios.CancelToken.source();
        this.$axios
          .get("/ccms/checkUserName", {
            params: {
              username: e
            },
            cancelToken: this.source.token
          })
          .then((res) => {
            if (res.data) {
              this.error = "Username already existed";
            } else {
              this.error = null;
            }
          });
      }
    }
  }
};
</script>
